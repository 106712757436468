<template>
  <div class="dialog-main exam-intro">
    <div class="exam-head">
      <h4>{{examData.title}}</h4>
      <ul class="clearfix">
        <li>
          <div class="wrap">
            <div class="value">{{examData.post_num}}道</div>
            <div class="label">题目总数</div>
          </div>
        </li>
        <li>
          <div class="wrap">
            <div class="value">{{examData.score}}分</div>
            <div class="label">试卷总分</div>
          </div>
        </li>
        <li>
          <div class="wrap">
            <div class="value">{{examData.passing_score}}分</div>
            <div class="label">及格分数</div>
          </div>
        </li>
        <li>
          <div class="wrap">
            <div class="value">{{examData.test_time}}人</div>
            <div class="label">已答人数</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="exam-body">
      <h5>试卷介绍</h5>
      <div v-html="examData.post_content"></div>
    </div>
    <div class="exam-foot h-btn2-group">
      <button class="btn" @click="toExam(1)">练习模式</button>
      <button class="btn" @click="toExam(2)">考试模式</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    examData: Object
  },
  methods: {
    toExam(type) {
      if (!this.$store.getters.uniqid) {
        this.$root.Bus.$emit('showLogin')
        return
      }
      this.$router.push({
        name: 'exam',
        params: {
          id: this.examData.id
        },
        query: {
          type,
          version: this.examData.version
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.exam-intro {
  // height: 600px;
  // display: flex;
  // flex-direction: column;
  // overflow: hidden;
  .exam-head {
    flex-shrink: 0;
    height: 180px;
    background: $mainColor;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
    padding-top: 41px;
    text-align: center;
    h4 {
      font-size: 24px;
      font-weight: 500;
    }
    ul {
      margin-top: 42px;
    }
    li {
      line-height: 1;
      float: left;
      width: 25%;
      border-right: 1px solid #ffc4aa;
      text-align: center;
      &:last-child {
        border-right: none;
      }
      .value {
        font-size: 18px;
        font-weight: bold;
      }
      .label {
        margin-top: 9px;
      }
    }
  }
  .exam-body {
    flex: 1;
    overflow: auto;
    padding: 28px 30px 0;
    background: #fff;
    h5 {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 500;
    }
    // p {
    //   font-size: 16px;
    //   color: #666666;
    //   line-height: 26px;
    // }
  }
  .exam-foot {
    flex-shrink: 0;
    padding: 20px 0 40px;
    background: #fff;
  }
}
</style>