<template>
  <div class="exam-item-1" @click="select(item.id)">
    <div class="wrap clearfix">
      <div class="date">{{item.published_time}}</div>
      <div class="count">已有{{item.num}}人做过</div>
      <div class="title">{{item.title}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props: {
    item: Object
  },
  methods: {
    select(id) {
      this.$emit('select', id)
    }
  }
}
</script>
<style lang="scss" scoped>
.exam-item-1 {
  margin-bottom: 16px;
  background: #fff;
  padding: 18px 20px 18px 0;
  cursor: pointer;
  &:hover {
    color: $mainColor;
  }
  .date {
    float: left;
    width: 80px;
    height: 24px;
    background: #fff7f3;
    border-radius: 0px 4px 4px 0px;
    line-height: 24px;
    text-align: center;
    font-size: 14px;
    color: $mainColor;
  }
  .count {
    float: right;
    text-align: right;
    color: $textLightColor;
    font-size: 14px;
  }
  .title {
    margin-left: 90px;
    font-size: 18px;
    line-height: 24px;
  }
}
</style>