<template>
  <div class="exam-item-2" @click="select(item.id)">
    <div class="wrap ">
      <div class="title">{{item.title}}</div>
      <div class="clearfix down">
        <div class="info">
          <span>考试时间：<span class="text-main">{{item.test_time}}min</span></span>
          <span v-if="difficultyMap[item.difficulty]">难易度：<span class="text-main">{{difficultyMap[item.difficulty]}}</span></span>
        </div>
        <div class="count">已有{{item.num}}人做过</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      difficultyMap: {
        1: '低 ',
        2: '中',
        3: '高'
      }
    }
  },
  props: {
    item: Object
  },
  methods: {
    select(id) {
      this.$emit('select', id)
    }
  }
}
</script>
<style lang="scss" scoped>
.exam-item-2 {
  margin-bottom: 16px;
  background: #fff;
  padding: 28px 20px;
  cursor: pointer;
  &:hover {
    color: $mainColor;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .down {
    margin-top: 12px;
    font-size: 14px;
    color: $textSubColor;
    .info {
      float: left;
      & > span {
        margin-right: 18px;
      }
    }
    .count {
      float: right;
    }
  }
}
</style>