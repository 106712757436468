<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 10:25:44
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-19 12:57:37
-->
<template>
  <bodyLayer :type="2" @click="selectType">
    <div class="base">
      <div class="hd">
        <div class="tab-content">
          <div v-show="query.type==2||query.type==3||query.type==4" class="h-btn-group">
            <button class="btn" v-for="item in categoryOptions" :key="item.value" :class="{active:query.cate_id==item.value}" @click="selectCategory(item.value)">
              {{item.label}}
            </button>
          </div>
          <div class="list-container">
            <scroll-list ref="list" :api="api" :query="query">
              <div slot-scope="scope">
                <template v-if="query.type==1">
                  <exam-item1 v-for="item in scope.list" :key="item.id" :item="item" @select="selectItem"></exam-item1>
                </template>
                <template v-else>
                  <exam-item2 v-for="item in scope.list" :key="item.id" :item="item" @select="selectItem"></exam-item2>
                </template>
              </div>

            </scroll-list>
          </div>
        </div>
      </div>
      <links></links>
    </div>
    <el-dialog custom-class="h-dialog" width="6rem" title="" :show-close="false" :visible.sync="introVisible" append-to-body>
      <exam-intro :examData="examData"></exam-intro>
    </el-dialog>
  </bodyLayer>
</template>
<script>
import bodyLayer from '@/components/bodyLayer.vue';
import links from '@/components/links.vue';
import scrollList from '@/components/scrollList'
import examItem1 from './components/examItem1'
import examItem2 from './components/examItem2'
import examIntro from './components/examIntro'
import { getExamPaperList, getExamCategory, getExamPaper } from '@/api/exam'
export default {
  components: {
    bodyLayer,
    links,
    scrollList,
    examItem1,
    examItem2,
    examIntro
  },
  data() {
    return {
      introVisible: false,
      keywords: '',
      api: getExamPaperList,
      query: {
        type: 1,
        uniqid: '',
        cate_id: ''
      },
      categoryOptions: [],
      examData: {},//试卷介绍
    }
  },
  created() {
    this.query.uniqid = this.$store.getters.uniqid || ''
  },
  methods: {
    async getExamCategory(type) {
      const { data } = await getExamCategory({
        type: type == 2 ? 5 : type,
        uniqid: this.$store.getters.uniqid || ''
      })

      this.categoryOptions = data.map(item => ({
        value: item.id,
        label: item.name
      }))
      //默认选择第一项
      if (this.categoryOptions.length > 0) {
        this.selectCategory(this.categoryOptions[0].value)
      }
    },
    toSearch() {
      if (!this.keywords) {
        this.$message.warning('请输入关键词')
        return
      }
      this.$router.push({
        name: 'search',
        query: {
          keywords: this.keywords
        }
      })
    },
    search() {
      this.$refs['list'].search()
    },
    selectType(value) {
      this.query.type = value
      this.query.cate_id = ''
      if (value != 1) {
        this.getExamCategory(this.query.type)
        //这里search是因为可能出现没有分类的情况
        this.search()
      } else {
        this.search()
      }
    },
    selectCategory(value) {
      this.query.cate_id = value;
      this.search();
    },
    async selectItem(id) {
      if (!this.$store.getters.uniqid) {
        this.$root.Bus.$emit('showLogin')
        return
      }
      const { data } = await getExamPaper({
        id,
        uniqid: this.$store.getters.uniqid,
        type: ''
      })
      this.examData = data
      this.introVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.base {
  width: 100%;
  background-color: #fff;
  padding: 30px;
  position: relative;
  .hd {
    display: flex;
    align-items: flex-start;
  }
}
// .exam-item-2, .exam-item-1{
//   border-bottom: 1px solid rgba($color: #000000, $alpha: .1);
//   &:first-child{
//     border-top: 1px solid rgba($color: #000000, $alpha: .1);
//   }
// }
.exam-item-1{
  margin-bottom: 0;
  padding: 30px 0;
}
.tab-head, .tab-content {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.h-btn-group {
  padding-top: 30px;
}
.list-container {
  padding-top: 16px;
  padding-bottom: 60px;
}
</style>